import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. What types of businesses can benefit from performance marketing?',
    content:
      'Performance marketing is highly versatile and can benefit businesses of all sizes and industries. Whether you’re an e-commerce store, a B2B service provider, or a local business, we can tailor a strategy to meet your specific goals.',
  },
  {
    title: '02. How do you determine which performance marketing channels to use?',
    content:
      'We conduct a thorough analysis of your target audience, business goals, and competitive landscape to determine the most effective channels for your campaigns. Our recommendations are based on data and industry best practices.',
  },
  {
    title: '03. How do you measure the success of performance marketing campaigns?',
    content:
      'Success is measured through key performance indicators (KPIs) such as ROI, CPA, CTR, and conversion rates. We provide detailed reports and insights to track and optimize performance.',
  },
  {
    title: '04. Can you help with both short-term campaigns and long-term strategies?',
    content:
      'Yes! We offer both short-term, high-impact campaigns as well as long-term performance marketing strategies that are designed to sustain growth and profitability over time.',
  },
  {
    title: '05. How often do you optimize campaigns?',
    content:
      ' We continuously monitor and optimize your campaigns, making adjustments as needed based on real-time data. This ongoing optimization ensures that your campaigns remain effective and responsive to changing market conditions.',
  },
];
const serviceListData = [
  {
    title: 'Maximized ROI',
    subtitle:
      ' Our performance marketing strategies focus on measurable results, ensuring that every dollar spent delivers a high return on investment.',
    imgUrl: '/images/sd/pm/1.png',
    href: '#',
  },
  {
    title: 'Data-Driven Precision',
    subtitle:
      ' We use real-time data to optimize campaigns, ensuring your ads reach the right audience at the right time, driving targeted traffic and conversions.',
    imgUrl: '/images/sd/pm/2.png',
    href: '#',
  },
  {
    title: 'Scalable Growth',
    subtitle:
      'Our performance marketing campaigns are designed to scale with your business, allowing for adjustments based on performance and market demand.',
    imgUrl: '/images/sd/pm/3.png',
    href: '#',
  },
  {
    title: 'Multi-Channel Reach',
    subtitle:
      'We create coordinated campaigns across multiple channels, increasing your brand’s visibility and reach while driving conversions.',
    imgUrl: '/images/sd/pm/4.png',
    href: '#',
  },
];

export default function PerformanceMarketing() {
  pageTitle('Performance Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Maximizing ROI with Precision"
        subTitle="Performance Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/pm/pm.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Achieve measurable results and maximize your ROI with our Performance Marketing 
             services. We focus on data-driven strategies that deliver tangible outcomes, 
             from increased leads to higher conversions. By leveraging a combination of 
             targeted ads, precise audience segmentation, and continuous optimization, 
             we ensure every marketing dollar works harder for your business, driving 
             growth and ensuring you get the most out of your investment.
            </p>
            <p>
             Our approach is all about delivering results. We monitor and optimize 
             campaigns in real-time, ensuring your marketing efforts are always 
             aligned with your goals, providing a clear path to success.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Achieve measurable growth with data-driven performance marketing. 
                We optimize campaigns for ROI, leveraging SEO strategies to increase 
                conversions and outperform the competition.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Goal Setting and KPI Alignment"
                  subTitle="Define clear goals and KPIs that directly align with your overall business objectives."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Audience Targeting and Segmentation"
                  subTitle="Precisely target and segment audiences based on demographics, behaviors, and interests for maximum impact."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="  Creative and Ad Development"
                  subTitle="Develop compelling ads with attention-grabbing visuals and persuasive copy that drive user engagement."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Multi-Channel Campaign Execution"
                  subTitle="Execute campaigns across multiple channels to maximize reach and ensure consistent message delivery."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Real-Time Optimization"
                  subTitle="Continuously monitor and adjust campaigns in real-time for optimal performance and ROI."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Detailed Analytics and Reporting"
                  subTitle="Provide detailed reports with key performance insights, identifying successes and opportunities for improvement."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/oMEZsAHwXis?si=O3MdiEkd_LFQun69"
          bgUrl="/images/tech-startup/hero_video_bg.png"
          title=""
          titleVariant="cs_outline_text"
        />
      </div>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
