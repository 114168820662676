import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. Which social media platforms should my business focus on?',
    content:
      'The platforms that are right for you depend on your audience and what you’re trying to achieve. Audience research is an important part of the strategic development process as we assess what platforms will best reach your brand’s target audience.',
  },
  {
    title: '02. How do you measure the success of a social media campaign?',
    content:
      ' How do we measure success? How do we know one action worked better than another? And, most importantly, how is all of that ultimately going to translate into purchases? Karasiak runs through some key metrics: engagement rates, such as the percentage of followers who ‘like’ or comment on a post; followers themselves, including rate of growth; click-through rates; conversions; ROI. She can provide weekly, monthly or on-demand reports, and can set up dashboards to track or compare metrics and indicate the more effective courses of action.',
  },
  {
    title: '03. How do you find the right influencers for my brand?',
    content:
      'We find influencers aligned with your brand values, your audience demographics, their engagement rate, and their ‘authenticity’. We seek influencers who actually influence your target market.',
  },
  {
    title: '04. Can you create content for all my social media channels?',
    content:
      'Yes, we develop captivating content for every popular social media site and fine-tune the content to cater to each platform’s specific needs and your company’s unique goals.',
  },
  {
    title: '05. What’s the difference between organic social media and paid social media?',
    content:
      'It is the kind of social content that is posted daily, without any payment, and can grow reach and engagement organically. On the other hand, paid social is the act of promoting social posts within the platform so that you reach a larger, targeted audience quickly and for a cleared cost. We use a variety of both options to give your brand its desired reach and contribution.',
  },
];
const serviceListData = [
  {
    title: 'Extended Brand Reach',
    subtitle:
      'Our social media and influencer strategies expand your brand’s reach, connecting you with larger, engaged audiences across platforms.',
    imgUrl: '/images/sd/ssm/1.png',
    href: '#',
  },
  {
    title: 'Authentic Audience Engagement',
    subtitle:
      'By partnering with influencers who resonate with your target market, we build authentic connections that foster trust and loyalty.',
    imgUrl: '/images/sd/ssm/2.png',
    href: '#',
  },
  {
    title: 'Boosted Brand Visibility',
    subtitle:
      ' Our creative and consistent social media content increases brand visibility, ensuring your message stands out in a crowded digital landscape.',
    imgUrl: '/images/sd/ssm/4.png',
    href: '#',
  },
  {
    title: 'Cost-Effective Marketing',
    subtitle:
      ' By leveraging influencers and targeted social campaigns, we maximize your ROI, delivering impactful results without overspending.',
    imgUrl: '/images/sd/ssm/5.png',
    href: '#',
  },
];

export default function SocialMediaMarketing() {
  pageTitle('Social Media Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Connecting Brands with Audiences"
        subTitle="Social Media Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/ssm/SSM.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Engage your audience where they are most active with our Social Media and 
             Influencer Marketing services. We help you build authentic connections through 
             strategic content, targeted campaigns, and partnerships with influencers who 
             resonate with your brand. By amplifying your message across the right platforms, 
             we not only boost brand awareness but also foster genuine interactions that lead 
             to lasting customer loyalty and measurable growth.
            </p>
            <p>
              We focus on creating meaningful connections that drive real results. Our tailored 
              approach ensures your brand’s voice is heard by the right people, at the right time, 
              through the most impactful channels.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Maximize brand visibility with targeted social media and influencer marketing. 
                Our SEO-focused campaigns build authentic connections, driving engagement and 
                setting you apart from competitors.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" In-Depth Audience Research"
                  subTitle="Understand your target audience’s behaviors and preferences to craft relevant content."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Strategic Content Planning"
                  subTitle="Develop a content calendar aligned with your brand’s goals and audience engagement."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Influencer Alignment"
                  subTitle="Select influencers whose values and audiences match your brand for authentic partnerships."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Creative Content Development"
                  subTitle="Produce visually stunning and engaging content that drives interaction and sharing."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Data-Driven Optimization"
                  subTitle="Monitor performance metrics in real-time, adjusting strategies for maximum impact."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Comprehensive Reporting and Insights"
                  subTitle="Provide detailed reports with actionable insights to optimize future campaigns."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/Hvt1jS8bAm8?si=sqplW8nffGiW0t_K"
          bgUrl="/images/tech-startup/hero_video_bg.png"
          title=""
          titleVariant="cs_outline_text"
        />
      </div>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
