import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How do you ensure the content matches my brand’s voice?',
    content:
      'Each project starts with a thorough discovery session where we learn about your brand’s values, voice and target audience, and write content in the voice you developed.',
  },
  {
    title: '02. Can you write content for any industry?',
    content:
      'Sure! our writers have experience a vast array of fields. We do a thorough research to ensure your content is accurate, relevant, and aligned with your industry.',
  },
  {
    title: '03. What is your process for SEO optimization?',
    content:
      'We adhere to SEO best practices – ensuring the appropriate use of headings, keywords, meta descriptions and more – without sacrificing quality and readability in the final product, and striving to help your content rank higher on search engine results pages.',
  },
  {
    title: '04. How often should I update my website content?',
    content:
      'Regularly updating website content is important for your SEO and to keep your audience engaged. We recommend that key pages be reviewed and updated at least once every 6-12 months and more dynamic content like blogs to be updated regularly.',
  },
  {
    title: '05. What if I need content quickly?',
    content:
      'We cater to clients who need prompt services. If you have tight deadlines, let us know. We’ll do the best we can as long as it doesn’t compromise the quality of our work.',
  },
];
const serviceListData = [
  {
    title: 'Consistent Brand Voice',
    subtitle:
      ' We deliver clear, persuasive copy that aligns with your brand’s voice, maintaining consistency across all communications and platforms.',
    imgUrl: '/images/sd/cw/1.png',
    href: '#',
  },
  {
    title: 'Increased Conversions',
    subtitle:
      'Our compelling copy is designed to drive action, whether it’s clicks, sign-ups, or purchases, directly impacting your bottom line.',
    imgUrl: '/images/sd/cw/2.png',
    href: '#',
  },
  {
    title: 'SEO-Optimized Content',
    subtitle:
      ' We incorporate SEO best practices into our writing, improving your search engine rankings and driving more organic traffic to your site.',
    imgUrl: '/images/sd/cw/3.png',
    href: '#',
  },
  {
    title: 'Audience Engagement',
    subtitle:
      ' We craft copy that resonates with your audience, keeping them engaged and encouraging deeper interaction with your brand.',
    imgUrl: '/images/sd/cw/4.png',
    href: '#',
  },
];

export default function ContentWriting() {
  pageTitle('Content Writing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Crafting Words that Convert"
        subTitle="Content Writing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/cw/copy.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Words have the power to shape perceptions and influence decisions. 
             Our copywriting services are all about crafting persuasive, clear, 
             and impactful messages that resonate with your target audience. 
             Whether it’s website content, marketing materials, or social media 
             posts, we tailor every word to reflect your brand’s voice and values, 
             driving engagement and conversion while telling your story in a way 
             that truly connects.
            </p>
            <p>
             Our copy is designed to do more than inform—it’s crafted to inspire 
             action and build lasting relationships. We ensure your message is 
             both compelling and aligned with your business objectives.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Transform your messaging with persuasive, SEO-optimized copy. 
                We craft content that resonates with your audience, drives 
                conversions, and sets you apart from competitors.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Audience and Market Research"
                  subTitle="Conduct in-depth research to ensure content relevance and engagement with your target audience."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Brand Voice Alignment"
                  subTitle="Tailor copy to reflect your brand’s unique voice and messaging guidelines consistently."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="  SEO Integration"
                  subTitle="Incorporate SEO best practices to improve visibility and drive organic traffic."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Clear and Persuasive Messaging"
                  subTitle="Craft concise, compelling copy that effectively communicates key messages and drives action."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Collaborative Review Process"
                  subTitle="Work closely with you to refine copy, ensuring alignment with your vision and goals."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Finalization and Distribution"
                  subTitle="Assist in integrating copy across marketing channels for maximum impact and reach."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/pMGzBmW8GTE?si=0H2pK0oxhD_n_5Gg"
          bgUrl="/images/tech-startup/hero_video_bg.png"
          title=""
          titleVariant="cs_outline_text"
        />
      </div>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
