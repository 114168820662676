import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { Icon } from '@iconify/react';
import { pageTitle } from '../../helpers/PageTitle';

export default function SwethaFoundation() {
  pageTitle('Infosage Solutions');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title=" "
        subTitle="Swetha Foundation"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src="/images/portfolio/swetha/swetha.png" alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className='row' style={{display:"flex",justifyContent:"center"}}>
                <div className='col-lg-11'>
            
                    <div className="cs_portfolio_details_right">
                        <h2>Project Summary</h2>
                        <p>
                            For the Swetha Foundation project, we undertook a holistic approach to bring the organization's vision to life through a compelling digital presence. The project involved developing a website that not only conveyed the foundation's mission but also engaged visitors through a thoughtfully designed user experience. Every element of the site was crafted to reflect the foundation's values and initiatives, providing an intuitive and informative platform for users.
                            
                        </p>
                        <p>In addition to the website development, we coordinated the launch event to ensure a memorable introduction of the foundation's digital presence. This included producing launch videos that effectively communicated the foundation's story and objectives, capturing the essence of its work and impact. These videos served as a key tool in generating excitement and awareness around the launch.</p>
                        <p>
                            We also managed the creation and distribution of invitations for the launch event, ensuring that key stakeholders and supporters were informed and engaged. The event itself was meticulously organized, reflecting the foundation's ethos and providing an opportunity for attendees to connect with its mission on a deeper level.
                        </p>
                        <p>The project culminated in a successful launch that combined strategic planning, creative storytelling, and seamless execution, highlighting our ability to deliver a multifaceted digital experience for clients with diverse needs.</p>
                    </div>
                </div>
            </div>
          </div>
          <Spacing lg="115" md="60" />
          <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/portfolio/swetha/1l.png" alt="Thumb" />
            </div>
            <div>
              <img src="/images/portfolio/swetha/3.png" alt="Thumb" />
            </div>
            <div>
              <img src="/images/portfolio/swetha/4.png" alt="Thumb" />
            </div>
          </div>
        </div>
        <Spacing lg="90" md="60" />
        
      </div>
      <Spacing lg="150" md="80" />
    </>
  );
}
