import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';
// import MarketingAgencyPage from './components/Pages/MarketingAgencyPage';
// import StudioAgencyPage from './components/Pages/StudioAgencyPage';
// import DigitalAgencyPage from './components/Pages/DigitalAgencyPage';
import ServicePage from './components/Pages/ServicePage';
import AboutPage from './components/Pages/AboutPage';
// import BlogPage from './components/Pages/BlogPage';
// import BlogListPage from './components/Pages/BlogListPage';
// import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
// import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
// import TeamPage from './components/Pages/TeamPage';
// import TeamDetailsPage from './components/Pages/TeamDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import Layout2 from './components/Layout/Layout2';
// import TechStartupPage from './components/Pages/TechStartupPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
// import Layout3 from './components/Layout/Layout3';
import ErrorPage from './components/Pages/ErrorPage';
import BrandingAndLogo from './components/Pages/BrandingLogo';
import WebDesign from './components/Pages/WebDesign';
import WebDevelopment from './components/Pages/WebDevelopment';
import InfoGraphics from './components/Pages/Infographics';
import SocialMediaMarketing from './components/Pages/SocialMediaMarketing';
import ProductLaunchStrategy from './components/Pages/ProductLaunchStrategy';
import ContentWriting from './components/Pages/ContentWriting';
import VideoContent from './components/Pages/VideoContent';
import ScriptWriting from './components/Pages/ScriptWriting';
import SEO from './components/Pages/SEO';
import PerformanceMarketing from './components/Pages/PerformanceMarketing';
import CRO from './components/Pages/CRO';
import SwethaFoundation from './components/Pages/SwethaFoundation';
import PNEGoodFoods from './components/Pages/PNEGoodFoods';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout darkMode />}>
        {/* <Route
          path="marketing-agency"
          element={<MarketingAgencyPage darkMode />}
        />
        <Route path="studio-agency" element={<StudioAgencyPage darkMode />} />
        <Route path="digital-agency" element={<DigitalAgencyPage darkMode />} /> */}
        <Route path="about" element={<AboutPage darkMode />} />
        <Route path="service" element={<ServicePage />} />
        {/*service Details*/}
        <Route
          path="service/service-details"
          element={<ServiceDetailsPage />}
        />
        <Route path='service/branding-logo' element={<BrandingAndLogo/>}/>
        <Route path='service/web-design' element={<WebDesign/>}/>
        <Route path='service/web-development' element={<WebDevelopment/>}/>
        <Route path='service/infographics' element={<InfoGraphics/>}/>
        <Route path='service/content-writing' element={<ContentWriting/>}/>
        <Route path='service/video-content' element={<VideoContent/>}/>
        {/* <Route path='service/copy-writing' element={<BrandingAndLogo/>}/> */}
        <Route path='service/script-writing' element={<ScriptWriting/>}/>
        <Route path='service/product-launch-strategy' element={<ProductLaunchStrategy/>}/>
        <Route path='service/social-media-marketing' element={<SocialMediaMarketing/>}/>
        <Route path='service/seo' element={<SEO/>}/>
        <Route path='service/performance-marketing' element={<PerformanceMarketing/>}/>
        <Route path='service/cro' element={<CRO/>}/>

        {/* <Route path="blog" element={<BlogPage />} />
        <Route path="blog-list" element={<BlogListPage />} />
        <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} /> */}
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        />
        <Route
          path="portfolio/swetha-foundation"
          element={<SwethaFoundation/>}
        />
        <Route
          path="portfolio/PNE"
          element={<PNEGoodFoods/>}
        />
        {/* <Route path="case-study-details" element={<CaseStudyDetailsPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} /> */}
        <Route path="contact" element={<ContactPage />} />
      </Route>
      <Route path="/" element={<Layout2 darkMode />}>
        <Route index element={<Home />} />
        {/* <Route path="tech-startup" element={<TechStartupPage />} /> */}
      </Route>
  
      {/* End Light Mode */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
